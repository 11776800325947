/* Variables */
:root {
  --link_hover: #0f6aa2;
  --main-color: #0f6aa2;
  --text-color: #707070;
  --sec-color: #ededed;
  --gray-color: #4d4d4d;
  --danger-color: #dc3545;
  --unnamed-color-777777de: #777777de;
  --unnamed-color-18c5bf: #18c5bf;
  --unnamed-color-ffffffde: #ffffffde;
  --unnamed-color-4a4848: #4a4848;
  --unnamed-color-d8d4d4: #d8d4d4;
  --unnamed-color-f2f2f2: #f2f2f2;
  --unnamed-color-545454: #545454;
  --unnamed-color-212121de: #212121de;
  --unnamed-color-000000de: #000000de;
  --unnamed-color-000000: #000000;
  --light-color: #ffffff;
  --bckg-color: #f8f8f8;
  --font-size: 14px;
  --font-weight: 400;
  --font-family: "Roboto", "Droid Arabic Kufi", "Arial", sans-serif;
}

.statistic-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.statistic-item,
.statistic-total {
  width: 100%;
  box-shadow: 0px 0px 10px rgb(146, 144, 144);
  height: 400px;
  background-color: white;
  border-radius: 20px;
  padding: 20px 10px;
  overflow: hidden;
}

.statistic-total {
  background-color: #031d4d;
  padding: 0;
}

.content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  flex-wrap: wrap;
}

.content-wrapper .cardItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .content-wrapper .cardItem {
    flex-direction: column;
    width: 90%;
  }
  .statistic-item,
  .statistic-total {
    height: 300px;
  }

  .statistic-wrapper {
    flex-direction: column;
  }
}

.separator {
  margin: 20px 0px;
}

.content-wrapper h5 {
  color: #0b034dd1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 10px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: wheat;
}
